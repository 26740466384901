import React from "react";
import Layout from "../layouts/index";

import { FaArrowCircleLeft } from "react-icons/fa";

import { graphql } from "gatsby";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

class SuccessPage extends React.Component {
  render() {
    return (
          <Layout path={this.props.location.pathname}>
            <div className="container mt-5">
              <h1 className="pt-5"><FormattedMessage id="Success_form" /></h1>
              <p className="lead"><FormattedMessage id="Success_form_info" /></p>
              <p className="mt-2">
                <Link to="/">
                  <FaArrowCircleLeft />
                  <span className="ml-1 lead"><FormattedMessage id="Back_to_home" /></span>
                </Link>
              </p>
            </div>
          </Layout>
    );
  }
}

export default SuccessPage

